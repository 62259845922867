import React, { PropsWithChildren } from 'react';
import { PageNotificationContainerStyles } from "./page-notification-container-styles";


export const PageNotificationContainer = ({ children }: PropsWithChildren<{}>) => {
    const classes = PageNotificationContainerStyles();
    return (
        <div data-testid="page-notification" className={classes.pageNotification}>
            <div className={classes.pageNotificationMessage}>{children}</div>
        </div>
    )
}
